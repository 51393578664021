export const surveyRowInteractions = [
  {
    name: "Identify on map",
    markdown: `
When you want to know exactly where a property is:
- Point at the property name cell to make the corresponding map marker pulse orange.
- Click the property name {% inlineRouterLink %}datapoint{% /inlineRouterLink %} and then select {% inlineAppIcon iconName="marker" /%}**center on map** to focus the map on the property.`,
  },
  {
    name: "Quick Actions",
    markdown: `
Click the marker's associated {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoint{% /inlineRouterLink %} to access its full {% inlineRouterLink %}action menu{% /inlineRouterLink %}. Highlights:
- {% inlineAppIcon iconName="pencilSquare" /%} {% inlineRouterLink %}Read and write notes{% /inlineRouterLink %}
- {% inlineAppIcon iconName="star" /%} {% inlineRouterLink articleId="create-a-task" %}Create a task{% /inlineRouterLink %}
- {% inlineAppIcon iconName="documentCheck" /%} {% inlineRouterLink %}View related tasks{% /inlineRouterLink %}
- {% inlineAppIcon iconName="rectangleStack" /%} View this marker's related diagram`,
  },
  {
    name: "Unlock",
    markdown: `
You can unlock the specific datapoints you need (ex. sizes & uses) from their {% inlineRouterLink %}action menus{% /inlineRouterLink %}.`,
  },
];

export const surveyProperties = {
  order: 9,
  name: "Survey properties",
  keywords: "filter list map property",
  subcategory: "The map",
  markdown: `# Survey properties

  When you need an overview of a market beyond your {% inlineRouterLink articleId="explored-areas" %}explored areas{% /inlineRouterLink %}, {% inlineRouterLink %}Big Map{% /inlineRouterLink %} offers tools to summarize and refine collections of {% inlineRouterLink %}datapoints{% /inlineRouterLink %}. Read on to learn more about property surveys.

  ## Start a survey

  Similar to other tools in the {% inlineRouterLink %}Analyze tab{% /inlineRouterLink %}, surveys display {% inlineRouterLink articleId="what-is-a-property?" %}properties{% /inlineRouterLink %} outside your {% inlineRouterLink articleId="explored-areas" %}explored areas{% /inlineRouterLink %} **and** from a further zoom level. From this vantage point, you can identify areas of interest and explore them to {% inlineRouterLink articleId="explored-areas" sectionName="Explored area effects" %}personalize your Tower Hunt experience{% /inlineRouterLink %}.

  {% callout type="caution" marginStyles="mb-4" %}
  **Caution:** Depending upon your geographic focus, a survey may fetch large volumes of data. This can result in longer load times and larger {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}datapoint costs{% /inlineRouterLink %} for you.
  {% /callout %}

  {% tablessContainer %}
  1. Navigate {% inlineRouterLink %}Big Map{% /inlineRouterLink %} to an area of interest.
  2. Visit the {% inlineAppIcon iconName="filter" %}**Filter**{% /inlineAppIcon %} section of the {% inlineRouterLink %}Analyze tab{% /inlineRouterLink %}.
  3. Summary metrics and corresponding properties will appear. The dataset updates whenever you {% inlineRouterLink articleId="time-travel" %}time travel{% /inlineRouterLink %} or {% inlineRouterLink articleId="map-movement" %}move the map{% /inlineRouterLink %}.
  ![Big Map filter screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ## Refine results

  Use the filters menu to adjust the focus of your results:

  ![Filters menu screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Summary metrics

  The first section of results displays summary metrics to provide context. These utilize {% inlineAppIcon iconName="calculator" %}{% inlineRouterLink %}**calculation datapoints**{% /inlineRouterLink %}{% /inlineAppIcon %} to synthesize and {% inlineRouterLink %}compensate{% /inlineRouterLink %} the {% inlineRouterLink %}contributors{% /inlineRouterLink %} of the underlying data.

  ![Summary metrics screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% callout type="note" marginStyles="mb-4" %}
  **Note:** Certain calculations (ex. sizes & uses) must be manually invoked because they can result in longer load times and larger datapoint costs for you. Once you have your survey location and settings where you want them, then run your summary calculation.
  {% /callout %}

  {% callout type="tip" %}
  **Tip:** Looking for more calculations? Read our {% inlineRouterLink %}intro to calculation datapoints{% /inlineRouterLink %} to learn more about what they do and how they are a win-win for customers and contributors.
  {% /callout %}

  ## Properties table

  This table lists the properties that meet the survey criteria. Use it to identify points of interest, quickly take action, and unlock datapoints on a property-by-property basis.

  ![Properties table screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% tabbedContainer tabs=$surveyRowInteractions marginStyles="mb-4" /%}

  {% callout type="tip" %}
  **Tip:** Wish you could see more of the table at once? Switch to {% inlineAppIcon iconName="layoutSelector" %}{% inlineRouterLink %}**top-and-bottom view**{% /inlineRouterLink %}{% /inlineAppIcon %}.
  {% /callout %}`,
};
